var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detailsDisplayArea" }, [
    _vm.needToShowHtml
      ? _c("div", {
          staticClass: "htmlContainer",
          domProps: { innerHTML: _vm._s(_vm.richDetail) }
        })
      : _c(
          "div",
          { staticClass: "arrayStructureContainer" },
          _vm._l(_vm.richDetailFromApp, function(item, index) {
            return _c("div", { key: item.key + index }, [
              item.key === "text"
                ? _c("span", { staticClass: "textStyle" }, [
                    _vm._v(_vm._s(item.content))
                  ])
                : _vm._e(),
              item.key === "image"
                ? _c("img", {
                    staticClass: "imageStyle",
                    style: { marginTop: "15px" },
                    attrs: { src: _vm.formartImageUrl(item.content) }
                  })
                : _vm._e(),
              item.key === "video"
                ? _c("video", {
                    staticClass: "videoStyle",
                    style: { marginTop: "15px" },
                    attrs: {
                      controls: "controls",
                      type: "video/mp4",
                      "webkit-playsinline": "true",
                      playsinline: "true",
                      "x-webkit-airplay": "allow",
                      "x5-video-player-type": "h5",
                      "x5-video-orientation": "portraint",
                      "x5-playsinline": "true",
                      "x5-video-player-fullscreen": "true",
                      src: item.content
                    }
                  })
                : _vm._e(),
              item.key === "url" && item.content.showLink
                ? _c("div", { style: { marginTop: "15px" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px"
                          },
                          attrs: {
                            src:
                              "https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                          }
                        }),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              "word-break": "break-all",
                              color: "#226CDB",
                              "font-size": "14px",
                              "margin-right": "25px",
                              "text-decoration": "none"
                            },
                            attrs: {
                              href: item.content.jumpLink,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(item.content.showLink))]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              item.key === "web"
                ? _c("div", { style: { marginTop: "15px" } }, [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          display: "flex",
                          "text-decoration": "none",
                          width: "100%",
                          height: "110px",
                          background: "#F7F7F7",
                          "border-radius": "8px",
                          position: "relative",
                          "align-items": "center",
                          "justify-content": "flex-start",
                          "box-sizing": "border-box",
                          padding: "0 24px"
                        },
                        attrs: {
                          href: item.content.url || "",
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              height: "70px",
                              display: "flex"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "70px",
                                  height: "70px",
                                  overflow: "hidden",
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center"
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                    "object-fit": "cover"
                                  },
                                  attrs: {
                                    src:
                                      item.content.image &&
                                      item.content.image != ""
                                        ? item.content.image
                                        : "https://tfile.melinked.com/2021/04/0b5a6b1f-acd0-488d-9fdc-a0cdd83220d1.png"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-flow": "column",
                                  "align-items": "flex-start",
                                  "justify-content": "flex-start",
                                  height: "70px",
                                  flex: "1",
                                  width: "0",
                                  overflow: "hidden",
                                  "box-sizing": "border-box",
                                  "padding-left": "15px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                      "line-height": "20px",
                                      "text-align": "left",
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                      color: "#373737"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.content.title))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                      "font-size": "12px",
                                      color: "#373737",
                                      "margin-top": "9px",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      display: "-webkit-box",
                                      "-webkit-line-clamp": "2",
                                      "-webkit-box-orient": "vertical"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.content.content))]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }