var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "detailsPreview", staticClass: "detailsPreview" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.canPreviousPage
          ? _c(
              "div",
              {
                staticClass: "buttonStyle leftStyle",
                on: { click: _vm.previousPicture }
              },
              [_c("span", { staticClass: "iconfont_Me icon-left iconStyle" })]
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.canNextPage
          ? _c(
              "div",
              {
                staticClass: "buttonStyle rightStyle",
                on: { click: _vm.nextPicture }
              },
              [_c("span", { staticClass: "iconfont_Me icon-right iconStyle" })]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass: "pictureContaineOuter",
          style: {
            transform: `translate3d(-${_vm.scrollWidth}px,0,0)`,
            width: `${_vm.totalNumberOfDisplayGroups * _vm.detailsPreviewWidth +
              8 * (_vm.pictures.length - 1)}px`
          }
        },
        _vm._l(_vm.pictures, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "pictureContainer",
              style: {
                width: `${_vm.sideLength}px`,
                height: `${_vm.sideLength}px`
              },
              on: {
                click: function($event) {
                  return _vm.openPreviewPop(index)
                }
              }
            },
            [_c("Picture", { attrs: { src: item } })],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }