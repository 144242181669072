var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.videoParameterCollection.length !== 0 ||
    _vm.pictures.length !== 0 ||
    _vm.filesCollection.length !== 0
    ? _c(
        "div",
        { staticClass: "detailsAnnex" },
        [
          _vm.videoParameterCollection.length !== 0
            ? _vm._l(_vm.videoParameterCollection, function(videoConfig) {
                return _c("VideoBlock", {
                  key: videoConfig.path,
                  staticClass: "annexBlock",
                  attrs: { isFromPostList: true, config: videoConfig }
                })
              })
            : _vm._e(),
          _vm.pictures.length !== 0
            ? [
                _vm.isFromRecommended
                  ? _c("PicturePreview", {
                      staticClass: "annexBlock",
                      attrs: {
                        pictures: _vm.pictures,
                        gigId: _vm.postInfo.gigId
                      }
                    })
                  : _c("DetailsPreview", {
                      staticClass: "annexBlock",
                      attrs: {
                        pictures: _vm.pictures,
                        gigId: _vm.postInfo.gigId
                      }
                    })
              ]
            : _vm._e(),
          _vm.filesCollection.length !== 0
            ? _vm._l(_vm.filesCollection, function(fileInfo) {
                return _c("FileItem", {
                  key: fileInfo.path,
                  staticClass: "annexBlock",
                  attrs: { fileInfo: fileInfo }
                })
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }