<template>
  <div class="detailsPreview" ref="detailsPreview">
    <transition name="fade">
      <div
        class="buttonStyle leftStyle"
        @click="previousPicture"
        v-if="canPreviousPage"
      >
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="buttonStyle rightStyle"
        @click="nextPicture"
        v-if="canNextPage"
      >
        <span class="iconfont_Me icon-right iconStyle"></span>
      </div>
    </transition>
    <div
      class="pictureContaineOuter"
      :style="{
        transform: `translate3d(-${scrollWidth}px,0,0)`,
        width: `${totalNumberOfDisplayGroups * detailsPreviewWidth +
          8 * (pictures.length - 1)}px`,
      }"
    >
      <div
        class="pictureContainer"
        :style="{ width: `${sideLength}px`, height: `${sideLength}px` }"
        v-for="(item, index) in pictures"
        :key="index"
        @click="openPreviewPop(index)"
      >
        <Picture :src="item"></Picture>
      </div>
    </div>
  </div>
</template>
<script>
import Picture from "../pictureWidget";
export default {
  components: {
    Picture,
  },
  props: {
    // 传入的图片集合
    pictures: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 帖子ID
    gigId: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 当前需要滚动的宽度
    scrollWidth() {
      return (this.detailsPreviewWidth + 8) * this.showIndex;
    },
    // 总共需要展示组数
    totalNumberOfDisplayGroups() {
      return Math.ceil(this.pictures.length / 3);
    },
    // 上一页按钮是否高亮
    canPreviousPage() {
      return this.showIndex != 0;
    },
    // 下一页按钮是否高亮
    canNextPage() {
      return this.showIndex < this.totalNumberOfDisplayGroups - 1;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.detailsPreviewWidth = this.$refs.detailsPreview.offsetWidth;
      this.sideLength = (this.detailsPreviewWidth - 16) / 3;
    });
  },
  data() {
    return {
      // 父容器宽度
      detailsPreviewWidth: 0,
      // 图片容器边长
      sideLength: 0,
      // 当前展示的位置
      showIndex: 0,
    };
  },
  methods: {
    // 上一张
    previousPicture() {
      if (this.canPreviousPage) {
        this.showIndex--;
      }
    },
    // 下一张
    nextPicture() {
      if (this.canNextPage) {
        this.showIndex++;
      }
    },
    // 前往大屏预览
    openPreviewPop(index) {
      this.$nextTick(() => {
        let multimediaPopupConfig = JSON.parse(
          JSON.stringify(this.$store.state.homePage.multimediaPopupConfig)
        );
        this.$set(multimediaPopupConfig, "pictureCollection", this.pictures);
        this.$set(multimediaPopupConfig, "pictureIndex", index);
        this.$set(multimediaPopupConfig, "gigId", this.gigId);
        this.$store.dispatch(
          "commitMultimediaPopupConfig",
          multimediaPopupConfig
        );
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.detailsPreview
  width 100%;
  overflow hidden;
  white-space:nowrap;
  position relative;
  .buttonStyle
    top 50%;
    width 40px;
    height 40px;
    cursor pointer;
    border-radius 50%;
    position absolute;
    background rgba(0,0,0,0.5);
    transform translate3d(0,-20px,0);
    z-index 2;
    display flex;
    align-items center;
    justify-content center;
    .iconStyle
      font-size 24px;
      color #FFFFFF;
  .leftStyle
    left 0;
  .rightStyle
    right 0;
  .pictureContaineOuter
    min-width 0;
    min-height 0;
    overflow hidden;
    transition transform .3s;
    .pictureContainer
      display inline-block;
      border-radius 8px;
      overflow hidden;
      margin-right 8px;
      border 1px solid #F7F7F7;
      &:last-child
        margin-right 0;
</style>
