<template>
  <div
    class="detailsAnnex"
    v-if="
      videoParameterCollection.length !== 0 ||
        pictures.length !== 0 ||
        filesCollection.length !== 0
    "
  >
    <template v-if="videoParameterCollection.length !== 0">
      <VideoBlock
        :isFromPostList="true"
        class="annexBlock"
        v-for="videoConfig in videoParameterCollection"
        :key="videoConfig.path"
        :config="videoConfig"
      ></VideoBlock>
    </template>
    <template v-if="pictures.length !== 0">
      <PicturePreview
        class="annexBlock"
        :pictures="pictures"
        v-if="isFromRecommended"
        :gigId="postInfo.gigId"
      ></PicturePreview>
      <DetailsPreview
        class="annexBlock"
        :pictures="pictures"
        :gigId="postInfo.gigId"
        v-else
      ></DetailsPreview>
    </template>
    <template v-if="filesCollection.length !== 0">
      <FileItem
        class="annexBlock"
        v-for="fileInfo in filesCollection"
        :key="fileInfo.path"
        :fileInfo="fileInfo"
      ></FileItem>
    </template>
  </div>
</template>
<script>
// 视频播放组件
import VideoBlock from "../../../components/videoPlayer/smallVersion";
// 普通附件组件
import FileItem from "../../../components/showFileItem";
// 详情页面图片展示组件
import DetailsPreview from "../../../components/picturePreview/detailsPreview";
import PicturePreview from "../../../components/picturePreview/basis";
export default {
  components: {
    VideoBlock,
    FileItem,
    PicturePreview,
    DetailsPreview,
  },
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否来自推荐列表，如果是，图片预览器需要替换
    isFromRecommended: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 附件视频配置文件集合
    videoParameterCollection() {
      // 兼容老数据，可能有多个视频文件，所有要展示所有视频，不能和首页一样只取第一个
      let videoParameterCollection = [];
      if (this.postInfo.attachments.length !== 0) {
        let videoItems = this.postInfo.attachments.filter((item) => {
          return this.matchFileSuffixType(item.path) === "video";
        });
        if (videoItems && videoItems.length !== 0) {
          videoItems.forEach((item) => {
            let videoConfig = {
              path: item.path,
              type: "small",
              volumeControl: true,
              currentTime: 0,
              // 当前是否是悬浮播放
              isFloating: false,
              // 是否显示浮窗控制按钮
              floatingWindowPlay: true,
              // 是否展示放大按钮
              zoomControl: true,
              // 是否展示下载按钮
              downloadControl: true,
              // 音量控制按钮是否展示
              volumeControl: true,
              // 从列表来的视频
              isFromTheList: true,
              // 帖子ID
              gigId: this.postInfo.gigId,
            };
            videoParameterCollection.push(videoConfig);
          });
        }
      } else {
        console.error("video文件列表为空");
      }
      return videoParameterCollection;
    },
    // 附件图片集合
    pictures() {
      let pictures = [];
      if (this.postInfo.attachments.length !== 0) {
        let imageItems = this.postInfo.attachments.filter((item) => {
          return this.matchFileSuffixType(item.path) === "image";
        });
        if (imageItems && imageItems.length !== 0) {
          imageItems.forEach((e) => {
            pictures.push(e.path);
          });
        }
      } else {
        console.error("image文件列表为空");
      }
      if (this.isFromRecommended) {
        pictures = pictures.slice(0, 2);
      }
      return pictures;
    },
    // 附件文件集合
    filesCollection() {
      let filesCollection = [];
      if (this.postInfo.attachments.length !== 0) {
        let fileItems = this.postInfo.attachments.filter((item) => {
          return (
            this.matchFileSuffixType(item.path) !== "video" &&
            this.matchFileSuffixType(item.path) !== "image"
          );
        });
        if (fileItems && fileItems.length !== 0) {
          fileItems.forEach((item) => {
            let fileInfo = {
              name: item.name,
              path: item.path,
              progress: "100.00",
            };
            filesCollection.push(fileInfo);
          });
        }
      } else {
        console.error("file文件列表为空");
      }
      return filesCollection;
    },
  },
};
</script>
<style lang="stylus" scoped>
.detailsAnnex
  width: 100%;
  padding 5px 0 0 0;
  display flex;
  flex-flow column;
  align-items center;
  justify-content center;
  overflow hidden;
  .annexBlock
    margin-bottom 10px;
</style>
