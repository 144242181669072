<template>
  <div class="detailsDisplayArea">
    <div class="htmlContainer" v-if="needToShowHtml" v-html="richDetail"></div>
    <div class="arrayStructureContainer" v-else>
      <div v-for="(item,index) in richDetailFromApp" :key="item.key+index">
        <span class="textStyle" v-if="item.key==='text'">{{item.content}}</span>
        <img class="imageStyle" v-if="item.key==='image'" :src="formartImageUrl(item.content)" :style="{marginTop:'15px'}"></img>
        <video 
          class="videoStyle"
          controls="controls"
          type="video/mp4"
          v-if="item.key==='video'"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
          x5-playsinline="true"
          x5-video-player-fullscreen="true"
          :src="item.content"
          :style="{marginTop:'15px'}"
        ></video>
        <div v-if="item.key==='url' && item.content.showLink" :style="{marginTop:'15px'}">
          <div style='display:flex;align-items:center;justify-content flex-start;'>
            <img style='width:16px;height:16px;margin-right:5px;' src='https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png'/>
            <a :href="item.content.jumpLink" target='_blank' style='word-break: break-all;color:#226CDB;font-size:14px;margin-right:25px;text-decoration:none;'>{{item.content.showLink}}</a>
          </div>
        </div>
        <div v-if="item.key==='web'" :style="{marginTop:'15px'}">
          <a :href="item.content.url || ''" target='_blank' style='display:block;text-decoration:none;width:100%;height:110px;background:#F7F7F7;border-radius:8px;position:relative;display:flex;align-items:center;justify-content:flex-start;box-sizing:border-box;padding:0 24px;'>
            <div style='width:100%;height:70px;display:flex;'>
              <div style='width:70px;height:70px;overflow:hidden;display:flex;align-items:center;justify-content:center'>
                <img style='width:100%;height:100%;object-fit:cover' :src="item.content.image&&item.content.image!=''?item.content.image:'https://tfile.melinked.com/2021/04/0b5a6b1f-acd0-488d-9fdc-a0cdd83220d1.png'">
              </div>
              <div style='display:flex;flex-flow:column;align-items:flex-start;justify-content:flex-start;height:70px;flex:1;width: 0;overflow:hidden;box-sizing:border-box;padding-left: 15px;'>
                <div style='width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;line-height:20px;text-align:left;font-size:14px;font-weight:bold;color:#373737'>{{item.content.title}}</div>
                <div style='width:100%;text-align:left;font-size:12px;color:#373737;margin-top:9px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;'>{{item.content.content}}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    // 帖子富文本内容数据
    richDetail() {
      let richDetail = '';
      // 这里的判读主要是因为刚创建组件的时候，还没有渲染到父组件的值的时候postInfo可能为undefined,判断detail这个值是因为这个值不管新老帖子这个值都是一直存在的
      if(this.postInfo.detail) {
        // 如果richDetail有值，则说明是从PC端发出的HTML字符串文本，否则则取detail内容
        if(typeof this.postInfo.richDetail === 'string' && this.postInfo.richDetail !== '') {
          // 这里是为了隐藏删除超链接预览的按钮
          richDetail = this.postInfo.richDetail.replace(/background:rgba[(]0,0,0,0.2[)]/g,'display:none');
        } else {
          // 这里是兼容老帖子，老帖子的数据是没有数组结构的
          if(this.postInfo.detail.indexOf('[') !== 0 || this.postInfo.detail.lastIndexOf(']') !== this.postInfo.detail.length-1) {
            richDetail = this.postInfo.detail;
          }
        }
        richDetail = richDetail.replace(/^\s+/," ");
      }
      return richDetail
    },
    // 来自APP发布的帖子内容数据
    richDetailFromApp() {
      let richDetailFromApp = [];
      // 判断原因同上
      if(this.postInfo.detail) {
        // 判断是否是从PC端发出的,PC端发出肯定会有richDetail这个属性
        let isFromPc = this.postInfo.richDetail && typeof this.postInfo.richDetail === 'string' && this.postInfo.richDetail !== '';
      // 兼容5.0.0新版本富文本数组结构数据
        if(!isFromPc && typeof this.postInfo.detail === 'string' && this.postInfo.detail !== '') {
          if(this.postInfo.detail.indexOf('[') === 0 && this.postInfo.detail.lastIndexOf(']') === this.postInfo.detail.length-1) {
            try {
              let dataFromApp = JSON.parse(this.postInfo.detail);
              dataFromApp.forEach(element => {
                if(element.key === 'url') {
                  try {
                    element.content = JSON.parse(element.content)
                  } catch (error) {}
                }
                richDetailFromApp.push(element)
              });
            } catch (error) {console.log('解析帖子详情失败',this.postInfo)}
          }
        }
      }
      return richDetailFromApp;
    },
    // 是显示HTML富文本还是显示数组富文本
    needToShowHtml() {
      return this.richDetailFromApp.length === 0 || (this.richDetailFromApp.length !== 0 && this.richDetail !== '')
    }
  },
  
}
</script>
<style lang="stylus" scoped>
  .detailsDisplayArea
    width 100%;
    min-height 0;
    overflow hidden;
    .arrayStructureContainer
      .textStyle
        font-size 14px;
      .imageStyle
        width 100%
      .videoStyle
        width 355.8px;
        height 200px;
        background #000;

</style>
<style lang="stylus">
  .detailsDisplayArea
    .htmlContainer
      white-space: pre-wrap;
      font-size 14px !important; 
      line-height 24px !important;
      img
        max-width 100% !important;
      video
        max-width 100% !important;
      p,span
        font-size 14px !important;
      ol,ul
        margin auto;
        padding 20px;
      ol
        list-style-type decimal;
      ul
        list-style-type disc;

</style>